import React from 'react';

export const StripeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={47} height={47} viewBox="0 0 47 47">
      <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
        <path fill="currentColor" d="M0 0h47v47H0V0Z" />
        <path fill="#fff"
              d="M21.6675 18.2705c0-1.1045.9048-1.5393 2.4088-1.5393 2.162 0 4.888.658 7.05 1.8213v-6.674c-2.3618-.94-4.6883-1.3043-7.0383-1.3043-5.7692 0-9.5997 3.008-9.5997 8.037 0 7.8373 10.7982 6.5918 10.7982 9.9758 0 1.3042-1.1397 1.7272-2.726 1.7272-2.3617 0-5.3697-.9635-7.755-2.2677v6.7562c2.6438 1.1398 5.311 1.6215 7.755 1.6215 5.9103 0 9.9758-2.9257 9.9758-8.0135-.047-8.46-10.8688-6.956-10.8688-10.1402Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h47v47H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}