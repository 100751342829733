import React from 'react'

function Outcomes(props) {
  const items = props.outcomes.map(item => (
    <li
      className={
        'outcomes__item ' + (props.fourItems ? 'outcomes__item--four' : '')
      }
      key={item.id}
    >
      <p className="outcomes__title text--4">{item.title}</p>
      <p className="outcomes__subtitle text--5">{item.subtitle}</p>
    </li>
  ))

  return (
    <section className="outcomes">
      <div className="container">
        <div className="outcomes__inner">
          <h2
            className="outcomes__heading
                        text--3
                        underline"
          >
            Outcomes
          </h2>
          <ul
            className={
              'outcomes__list ' +
              (props.fourItems ? 'outcomes__list--wrap' : '')
            }
          >
            {items}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Outcomes
